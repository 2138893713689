<template>

    <!-- Cart -->
    <div class="main-body">
        <h2 class="cart-header">Shopping Cart</h2>

        <div class="cart-wrapper">
            <div class="cart-content-list">
                <ul>
                    <li class="mc-item" v-for="item in cartItems" :key="item">
                        <div>
                            <img class="mc-image" :src="'https://firebasestorage.googleapis.com/v0/b/flikbox-2606.appspot.com/o/assets%2Fimages%2Fproducts%2F' + item.id +'%2F1.png?alt=media'" alt="Cart Image">
                        </div>
                        <div class="mc-product-details">
                            <div class="mc-name-price-feat-box">
                                <div class="mc-name-price-box">
                                    <a :href="'/product/'+item.id"><h3 class="mc-product-name">{{item.name}}</h3></a>
                                    <p class="mc-product-price">${{getItemSubtotal(item.price, item.quantity)}}</p>
                                </div>
                                <p class="mc-product-feature">{{item.size}}, {{item.color}}</p>
                            </div>
                            <div class="mc-qty-remove-box">
                                <div>
                                    <select name="qty" :id="'cart-item-qty'+item.id" class="mc-item-qty" @change="quantityUpdated(item)">
                                        <option v-if="item.quantity==1" value="1" selected>1</option>
                                        <option v-if="item.quantity!=1" value="1">1</option>
                                        <option v-if="item.quantity==2" value="2" selected>2</option>
                                        <option v-if="item.quantity!=2" value="2">2</option>
                                        <option v-if="item.quantity==3" value="3" selected>3</option>
                                        <option v-if="item.quantity!=3" value="3">3</option>
                                        <option v-if="item.quantity==4" value="4" selected>4</option>
                                        <option v-if="item.quantity!=4" value="4">4</option>
                                    </select>
                                </div>
                                <p @click="deleteFromCart(item)" class="mc-remove-btn">Remove</p>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="checkout-container-wrapper">
                <p class="mc-header">Order Summary</p>

                <div class="mc-subtotal-box">
                    <p class="subtotal-header">Subtotal</p>
                    <p class="subtotal-value">${{subTotal}}</p>
                </div>
                <div class="mc-subtotal-box">
                    <p class="subtotal-header">Shipping</p>
                    <p class="subtotal-value">FREE</p>
                </div>

                <div class="mc-total-box">
                    <p>Order Total</p>
                    <p>${{finalPrice}}</p>
                </div>

                <div class="btn mc-checkout-btn" @click="checkout()">
                    <p v-if="isSubmitting" style="height: 27px;position: relative;"><svg class="center" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="28px" height="28px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" style="/* margin-top: 20px; */background: none;shape-rendering: auto;position: relative;width: 100%;text-align: center;height: 29px;/* top: 4px; */"><circle cx="50" cy="50" fill="none" stroke="#fff" stroke-width="8" r="35" stroke-dasharray="164.93361431346415 56.97787143782138" transform="rotate(274.643 50 50)"><animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform></circle></svg></p>
                    <p v-if="!isSubmitting" class="sign-in-text">Checkout</p>
                </div>
            </div>
        
        </div>
    </div>

</template>

<script>
import firebase from 'firebase';
import { checkoutSession } from "@/apis/checkout";

export default {
    name: 'Cart',
    data(){
        return{
            cartItems: [],
            subTotal: 0.00,
            finalPrice: 0.00,
            isSubmitting: false,
            email: undefined,
            uid: undefined
        }
    },
    mounted(){
        this.checkAuthentication();

        this.getCartItems();
    },
    methods:{
        // Check if user is logged in
        checkAuthentication(){
            let self = this;
            firebase.auth().onAuthStateChanged((user) => {
                if (user != null) {
                   self.email = user.email;
                   self.uid = user.uid;
                }
            });
        },
        // Get Cart Items from Local Storage
        getCartItems(){
            var cartItems = JSON.parse(localStorage.getItem("cartItems"));
            if(cartItems != null){
                this.cartItems = cartItems;
            }
            
            this.calculatePrice();
        },
        // Get Subtotal of each Item
        getItemSubtotal(price, quantity){
            return price*quantity;
        },
        // Update Quantity of Cart Item
        quantityUpdated(item){
            let found = this.cartItems.some(el => (el.id === item.id) && (el.size === item.size) && (el.color === item.color));
            if (found){
                for(let i=0;i<this.cartItems.length;++i){
                    if((this.cartItems[i]["id"] == item.id) && (this.cartItems[i]["size"] == item.size) && (this.cartItems[i]["color"] == item.color)){
                        this.cartItems[i]["quantity"] = document.getElementById('cart-item-qty'+item.id).value;
                    }
                }
            }
            this.calculatePrice();
            localStorage.setItem("cartItems", JSON.stringify(this.cartItems));
            this.$root.$refs['navbar'].getCartItems();
        },
        /* Calculate Final Price of all Items */
		calculatePrice(){
			this.subTotal = 0.0;
			for(let i=0;i<this.cartItems.length;++i){
				this.subTotal = this.subTotal + (parseFloat(this.cartItems[i]["price"]) * parseInt(this.cartItems[i]["quantity"]))
			}
			this.finalPrice = (this.subTotal).toFixed(2);
		},
        // Delete Item from Cart
        deleteFromCart(item){
            for(let i=0;i<this.cartItems.length;++i){
                if((this.cartItems[i]["id"] == item.id) && (this.cartItems[i]["size"] == item.size) && (this.cartItems[i]["color"] == item.color)){
                    this.cartItems.splice(i, 1);
                }
            }
            localStorage.setItem("cartItems", JSON.stringify(this.cartItems));
            this.$root.$refs['navbar'].getCartItems();
        },
        // Checkout Stripe
        async checkout(){
            if(this.email!=undefined && this.uid!=undefined && this.cartItems.length>0){
                this.isSubmitting = true;

                let sku_list = [];
                for(let i=0;i<this.cartItems.length;++i){
                    sku_list.push({"id": this.cartItems[i]["id"], "name": this.cartItems[i]["name"], "quantity": this.cartItems[i]["quantity"], "price": this.cartItems[i]["price"], "size": this.cartItems[i]["size"], "color": this.cartItems[i]["color"]})
                }

                let orderData = {sku_list: sku_list, "email": this.email, "client_reference_id": this.uid}
                await checkoutSession(orderData);
            }
            else{
                this.isSubmitting = true;
                this.$root.$refs['snackbar'].showToast("Please Sign In to Purchase. Redirecting.", "#e52627");
                setTimeout(function(){
                    window.location.href = "/sign-in?redirect=checkout";
                }, 3000);
            }
        }
    }
}
</script>

<style scoped>
    .main-body{max-width: 1280px;margin: auto;padding: 0 12px;padding: 32px 0px;padding-bottom: 0px;min-height: calc(100vh - 170px);}
    .cart-header{text-transform: capitalize;padding: 0 12px;margin-bottom: 12px;text-align: left;line-height: 1.5;font-size: 22px;font-weight: 500;margin: 0;}

    .cart-wrapper{display: flex;grid-template-columns: auto 1fr;padding: 12px;justify-content: space-between;grid-gap: 24px;}
    .cart-content-list{width: 100%;max-width: 650px;}
    .mc-item{padding: 24px 0px;display: flex;}
    .mc-image{height: 90px;width: 70px;object-fit: cover;object-position: center;border-radius: 6px;background-color: #f1f1f1;}
    .mc-product{display: grid;grid-template-columns: 80px auto;width: 100%;grid-gap: 12px;margin-bottom: 12px;padding-bottom: 12px;border-bottom: 1px solid #e9e9e9;}
    .mc-product-details{flex-direction: column;flex: 1 1 0%;display: flex;margin-left: 16px;}
    .mc-name-price-feat-box{position: relative;}
    .mc-name-price-box{line-height: 24px;display: flex;justify-content: space-between;}
    .mc-product-name{font-size: 16px;font-weight: 500;}
    .mc-product-price{margin-left: 16px;font-size: 14px;line-height: 20px;font-weight: 500;}
    .mc-product-feature{color: #6b7280;font-size: 14px;line-height: 20px;margin-top: 4px;}
    .mc-qty-remove-box{flex: 1 1 0%;display: flex;align-items: flex-end;justify-content: space-between;margin-top: 4px;}
    .mc-item-qty{background-color: #fff;padding: 4px;font-weight: 500;color: #495057;font-size: 14px;border: 1px solid #d1d5db;border-radius: 6px;box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgb(0 0 0 / 5%);user-select: none;outline: none;}
    .mc-remove-btn{color: #6366f1;font-size: 14px;font-weight: 500;cursor: pointer;padding: 6px 0px;}
    .mc-remove-btn:hover{text-decoration: underline;}

    .checkout-container-wrapper{padding: 24px;width: 400px;background-color: #f9fafc;border-radius: 6px;}
    .mc-subtotal-box, .mc-total-box{flex: 1 1 0%;display: flex;align-items: flex-end;justify-content: space-between;font-weight: 500;padding: 12px 0px;border-bottom: 1px solid #e5e7eb;}
    .mc-total-box{border: none;}
    .subtotal-header{color: #6b7280;font-size: 14px;line-height: 20px;font-weight: 400;}
    .subtotal-value{font-size: 14px;line-height: 20px;}
    .mc-header{text-align: left;line-height: 1.5;font-size: 18px;font-weight: 500;margin: 0;margin-bottom: 20px;}

    .mc-checkout-btn{margin: 0;width: 100%;font-size: 16px;font-weight: 400;line-height: 20px;padding: 12px;margin-top: 24px;}
    .mc-checkout-btn{margin: 0px;min-width: 120px;width: 100%;margin-top: 12px;padding: 7px 16px;line-height: 27px;}
    .sign-in-text{font-size: 15px;}

    /* Tablet */
    @media only screen and (max-width: 1024px){
        .cart-content-list{max-width: 400px;}
    }
    
    /* Phone */
    @media only screen and (max-width: 600px){
        .cart-wrapper{display: block;}
        .checkout-container-wrapper{width: 100%;padding: 24px 12px;margin-top: 32px;}
        .mc-item{padding: 16px 0px;}
        .mc-image{height: 80px;}
    }
</style>