const stripe = window.Stripe('pk_test_51JVGfyCpnsatSeoyf5ggxh2DKCytSBedTM7Ft9zglU3U4VwipTXnQHYiT0pegHIEV8j1zLenLv4IoE6EVZ8rD7U500szQIX4S0');


// Function to get product data from firestore
export const checkoutSession = async (orderData) => {
    let status = {};

    status = await fetch("https://us-central1-flikbox-2606.cloudfunctions.net/checkoutSession", {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(orderData)
    })
    .then((response) => {
        return response.json();
    })
    .then((data) => {
        var sessionId = data.id;
        stripe.redirectToCheckout({sessionId}).then(function(result){console.log(result)});
	});

    return status
}